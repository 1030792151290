import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import { isUserLoggedIn } from "utils/functions"

const Hero = () => {
  const auth = isUserLoggedIn()

  return (
    <section className="home-hero">
      <div className="container-fluid">
        <h1>
          Poznaj Trust Investment
          <br /> Projekt 14
        </h1>
        <p>i porozmawiajmy o dobrej współpracy!</p>
        <div className="home-hero__cta">
          {auth ? (
            <Link
              to="/projekt-14/"
              className="button button--circle button--circle-white"
            >
              Poznaj
              <br /> Projekt 14
            </Link>
          ) : (
            <a
              className="button button--circle button--circle-white"
              href="https://trustinvestment.pl/mieszkania/kielce/sky-trust-ii/"
              target="_blank"
              rel="noreferrer"
            >
              Poznaj
              <br /> Projekt 14
            </a>
          )}
        </div>
      </div>
    </section>
  )
}

export default Hero
